export var apiUrls = {
    baseUrl: process.env.REACT_APP_API_URL,
    localBaseUrl: process.env.REACT_APP_API_URL,
    openApiUrl: process.env.REACT_APP_OPENAPI_URL,
    localOpenApiUrl: process.env.REACT_APP_OPENAPI_URL,
    live: {
        login: {
            login: {
                url: "/login",
                version: "/v1",
                requestType: "POST",
            },
            logout: {
                url: "/logout",
                version: "/v1",
                requestType: "POST",
            },
            authenticateByAzureAD: {
                url: "/authenticateByAzureAD",
                version: "/v1",
                requestType: "GET",
            },
            getAzureADRefreshToken: {
                url: "/getAzureADRefreshToken",
                version: "/v1",
                requestType: "POST",
            },
            authCookiesToken: {
                url: "/authCookiesToken",
                version: "/v1",
                requestType: "POST",
            },
            initAzure: {
                url: "/initAzure",
                version: "/v1",
                requestType: "POST",
            },
            checkSessionInfo: {
                url: "/checkSessionInfo",
                version: "/v1",
                requestType: "GET",
            },
        },
        userPermission: {
            getuserPrivilege: {
                url: "/userPrivilege",
                version: "/v1",
                requestType: "GET",
            },
            getAuditLogDetail: {
                url: "/roleprivilege/getRoleAuditLog",
                version: "/v1",
                requestType: "GET",
            },
            getAuditLogSearchField: {
                url: "/privileges/GET",
                version: "/v1",
                requestType: "GET",
            },
        },
        openAPI: {
            flagFeature: {
                url: "/master/featureflag",
                version: "/v1",
                requestType: "GET",
            },
            downloadUrl: {
                url: "/document/ballot",
                version: "/v1",
                requestType: "GET",
            },
            getAllCommittees: {
                url: "/committees",
                version: "/v2",
                requestType: "GET",
            },
        },
        documents: {
            getBallotsList: {
                url: "/getBallotsList",
                version: "/v1",
                requestType: "POST",
            },
            getActiveCommittees: {
                url: "/getActiveCommittees",
                version: "/v1",
                requestType: "GET",
            },
            getUserPreferences: {
                url: "/getUserPreferences",
                version: "/v1",
                requestType: "GET",
            },
            saveUserPreferences: {
                url: "/saveUserPreferences",
                version: "/v1",
                requestType: "POST",
            },
            getBallotTypes: {
                url: "/getBallotTypes",
                version: "/v1",
                requestType: "GET",
            },
            getActiveCommitteesFromIA: {
                url: "/getActiveCommittees",
                version: "/v1",
                requestType: "GET",
            },
            saveBallotHeader: {
                url: "/saveBallotHeader",
                version: "/v1",
                requestType: "POST",
            },
            getAllBallotStatus: {
                url: "/getAllBallotStatus",
                version: "/v1",
                requestType: "GET",
            },
            getAppUserList: {
                url: "/getAppUserList",
                version: "/v1",
                requestType: "GET",
            },
            getWorkItemList: {
                url: "/getWorkItemList",
                version: "/v1",
                requestType: "GET",
            },
            getWorkDetailList: {
                url: "/workItemAdmin/workItemDetail/",
                version: "/v1",
                requestType: "GET",
            },
            getStandardTypeList: {
                url: "/workItemAdmin/standardTypes",
                version: "/v1",
                requestType: "GET",
            }
        },
        workItems: {
            getDTNList: {
                url: "/getDTNList",
                version: "/v1",
                requestType: "POST"
            }
        },
        workItemAdmin: {
            workItemDetails: {
                url: "/workItemAdmin/workItemDetail/",
                version: "/v1",
                requestType: "GET",
            },
            standardTypeList: {
                url: "/workItemAdmin/standardTypes",
                version: "/v1",
                requestType: "GET",
            },
            updateWorkItemDetails: {
                url: "/workItemAdmin",
                version: "/v1",
                requestType: "PUT",
            },
            deleteWorkItem: {
                url: "/workItemAdmin/Delete",
                version: "/v1",
                requestType: "PUT",
            },
            restoreWorkItem: {
                url: "/workItemAdmin/recoverWorkItem/",
                version: "/v1",
                requestType: "PUT",
            },
            deletedWorkItemList: {
                url: "/workItemAdmin/viewDeletedWorkItem",
                version: "/v1",
                requestType: "POST",
            },
            workItemAuditLogs: {
                url: "/workItemAdmin/workItemAuditLogs",
                version: "/v1",
                requestType: "GET",
            },
            getSubCommitteesList: {
                url: "/workItemAdmin/getSubCommitteeList/",
                version: "/v1",
                requestType: "GET",
            },
            getMemberList: {
                url: "/workItemAdmin/getMemberList/",
                version: "/v1",
                requestType: "GET",
            },
            getBallotItemSubmissionList: {
                url: "/workItemAdmin/getBallotItemSubmissionList",
                version: "/v1",
                requestType: "GET",
            },
            getPreReleaseTargetDateList: {
                url: "/workItemAdmin/getPreReleaseTarget/",
                version: "/v1",
                requestType: "PUT",
            },
            auditLog: {
                url: "/workItemAdmin/workItemAuditLog",
                version: "/v1",
                requestType: "POST",
            },
            sendCollabApiFailureEmail: {
                url: "/workItemAdmin/sendCollabApiFailureEmail",
                version: "/v1",
                requestType: "POST",
            },
            getDTNDetailAction: {
                url: "/getDTNDetailAction",
                version: "/v1",
                requestType: "POST"
            },
        },
    },
    local: {
        login: {
            login: {
                url: "/login",
                version: "/v1",
                requestType: "GET",
            },
        },
    },
};
function getApiUrl(key, name, isOpenAPICall) {
    if (isOpenAPICall === void 0) { isOpenAPICall = false; }
    var url;
    var baseURL = isOpenAPICall === true ? apiUrls.openApiUrl : apiUrls.baseUrl;
    if (baseURL !== null) {
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = baseURL + apiUrls.live[key][name]["version"] + apiUrls.live[key][name]["url"];
        }
        else {
            if (apiUrls.local[key] && apiUrls.local[key][name]) {
                var localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
                url = localURL + apiUrls.local[key][name]["version"] + apiUrls.local[key][name]["url"];
            }
        }
    }
    else {
        if (apiUrls.local[key] && apiUrls.local[key][name]) {
            var localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
            url = localURL + apiUrls.local[key][name]["version"] + apiUrls.local[key][name]["url"];
        }
    }
    return url;
}
export default getApiUrl;
