var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import lodash from "lodash";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import AdvanceSearchPopOver from "../AdvanceSearchPopOver";
import { useSelector } from "react-redux";
var SearchBox = function (props) {
    var _a = useState(""), currentSearchOption = _a[0], setCurrentSearchOption = _a[1];
    var _b = useState(""), currentSearchText = _b[0], setCurrentSearchText = _b[1];
    var _c = useState([]), searchOptions = _c[0], setSearchOptions = _c[1];
    var _d = useState(false), showAdvanceSearchPopOver = _d[0], setShowAdvanceSearchPopOver = _d[1];
    var ballotGlobalSearchTextRef = useRef(null);
    var popoverRef = useRef(null);
    var toggleRef = useRef(null);
    var globalSearchText = useSelector(function (state) { return state.commonReducer.globalSearchText; });
    useEffect(function () {
        setUpInitialSearchState();
        var path = window.location.pathname;
        var pageName = path.split('/');
        if (pageName.some(function (page) { return ["workItemDetail", "dtnDetail"].includes(page); })) {
            setCurrentSearchOption("workitem");
        }
        else if (pageName.some(function (page) { return ["ballotDetail"].includes(page); })) {
            setCurrentSearchOption("ballot");
        }
        else {
            var currentOption = path.split('/').filter(Boolean).pop();
            setCurrentSearchOption(currentOption);
        }
    }, [window.location.pathname]);
    useEffect(function () {
        setCurrentSearchText(globalSearchText);
    }, [window.location.pathname, globalSearchText]);
    useEffect(function () {
        if (props.resetClicked) {
            ballotGlobalSearchTextRef.current.value = "";
            props.setResetClicked(false);
        }
    }, [props.resetClicked]);
    var setUpInitialSearchState = function () {
        var initialOptions = [{ id: 1, name: "Ballots", value: "ballot" }, { id: 2, name: "Work Items", value: "workitem" }];
        var uniqueOptions = lodash.uniqBy(initialOptions, "value");
        setSearchOptions(uniqueOptions);
        // Initialize currentSearchOption if not found in searchOptions
        if (uniqueOptions.length > 0 && !uniqueOptions.find(function (opt) { return opt.value === currentSearchOption; })) {
            setCurrentSearchOption(uniqueOptions[0].value);
        }
        //This code will be added when authorization will be done
        /*let searchOptions = getSearchAllGlobalDropDownOptions();
            searchOptions = lodash.uniqBy(searchOptions, (result: any) => result?.value)
            if (searchOptions && searchOptions.length > 0) {
                let index = searchOptions.findIndex((option: any) => option?.value === currentSearchOption);
                if (index === -1) {
                    setCurrentSearchOption(searchOptions[0].value);
                }
            }*/
    };
    var handleSearchInputChange = function (ev) {
        setCurrentSearchText((ev.currentTarget.value || "").toString().trim());
    };
    var handleSearchInputKeyPress = function (ev) {
        if (ev.key === "Enter") {
            props.onGlobalSearchSubmit(currentSearchOption, currentSearchText);
        }
    };
    var onGlobalSearchSubmit = function () {
        var _a;
        var searchBoxValue = ((_a = ballotGlobalSearchTextRef.current) === null || _a === void 0 ? void 0 : _a.value.trim()) || "";
        if (lodash.isEmpty(searchBoxValue)) {
            setCurrentSearchText("");
            props.onGlobalSearchSubmit(currentSearchOption, "");
        }
        else {
            props.onGlobalSearchSubmit(currentSearchOption, currentSearchText);
        }
    };
    var handleDropdownSelect = function (value) {
        setCurrentSearchOption(value);
        setCurrentSearchText("");
    };
    var toggleAdvanceSearchVisibility = function () {
        if (currentSearchOption == "workitem")
            return;
        setShowAdvanceSearchPopOver(!showAdvanceSearchPopOver);
    };
    var handleClickOutside = function (event) {
        if (popoverRef.current && !popoverRef.current.contains(event.target) && toggleRef.current && !toggleRef.current.contains(event.target)) {
            setShowAdvanceSearchPopOver(false);
        }
    };
    useEffect(function () {
        if (showAdvanceSearchPopOver) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showAdvanceSearchPopOver]);
    if (searchOptions && searchOptions.length > 0) {
        var index = searchOptions.findIndex(function (option) { return option.value === currentSearchOption; });
        return (_jsxs("div", { className: "action", "data-testid": "advSearchContainer", children: [_jsx("div", { className: "form-group mb-0 mr16", children: _jsx("select", { value: currentSearchOption, name: currentSearchOption, className: "custom-select astm-custom-select", onChange: function (event) { return handleDropdownSelect(event.target.value); }, children: searchOptions.map(function (option) { return (_jsx("option", { value: option.value, children: option.name }, option.id)); }) }) }), _jsxs(InputGroup, { className: "search-by-design", children: [_jsx(FormControl, { ref: ballotGlobalSearchTextRef, placeholder: currentSearchOption == "workitem" ? "Search by work item, DTN or standard designation" : "Search by ballot designation", "aria-label": "Search", "aria-describedby": "basic-addon2", value: currentSearchText, onChange: handleSearchInputChange, onKeyDown: handleSearchInputKeyPress, "data-testid": "ballotSearch" }), _jsx("i", { ref: toggleRef, className: "astm-icon fas fa-caret-down arrow-down-btn", "data-testid": "AdvanceBallotSearch", onClick: toggleAdvanceSearchVisibility })] }), _jsx(Button, { variant: "warning top-search-ic", "data-testid": "ballotsearchbutton", onClick: onGlobalSearchSubmit, children: _jsx("i", { className: "astm-icon far fa-search" }) }), showAdvanceSearchPopOver && _jsx(AdvanceSearchPopOver, __assign({ ballotGlobalSearchTextRef: ballotGlobalSearchTextRef, popoverRef: popoverRef }, props, { toggleAdvanceSearchVisibility: toggleAdvanceSearchVisibility, ballotStatusOption: props.ballotStatusOption, onGlobalSearchSubmit: props.onGlobalSearchSubmit }))] }));
    }
    return "";
};
export default SearchBox;
