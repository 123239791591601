export var fields = [
    { label: "Standard", type: "textarea", key: "RevisedStandardName", isDisabled: true },
    { label: "Proposed Standard", type: "textarea", key: "Title", isDisabled: true },
    { label: "Standard Type", type: "text", key: "StandardTypeId", isDisabled: true },
    { label: "WI Status", type: "text", key: "WorkItemStatus", isDisabled: true },
    { label: "WI Type", type: "text", key: "WorkItemTypeId", isDisabled: true },
    { label: "Sponsoring Subcommittee", type: "text", key: "SubSectionCommitteeList", isDisabled: true },
    { label: "Replaced By", type: "text", key: "ReplacedBy", isDisabled: true },
    { label: "Technical Contact", type: "text", key: "MemberList", isDisabled: true },
    { label: "Collaboration Area", type: "text", key: "IsOnlineCollaboration", isDisabled: true },
    { label: "Patented", type: "text", key: "IsPatented", isDisabled: true },
    { label: "Authorize Date", type: "date", key: "AuthorizationDate", isDisabled: true },
    { label: "Copyright", type: "text", key: "Copyright", isDisabled: true },
    { label: "Copyright Acceptance Date", type: "date", key: "CopyrightAcceptanceDateTime", isDisabled: true },
    { label: "Target Ballot Date", type: "date", key: "TargetBallotDate", isDisabled: true },
    { label: "Emergency Response", type: "textarea", key: "IsEmergencyResponse", isDisabled: true },
    { label: "Emergency Description", type: "textarea", key: "EmergencyDescription", isDisabled: true },
    { label: "Scope", type: "textarea", key: "Scope", isDisabled: true },
    { label: "Keywords", type: "textarea", key: "Keywords", isDisabled: true },
    { label: "Rationale", type: "textarea", key: "Rationale", isDisabled: true },
    { label: "Existing Standards", type: "textarea", key: "ExistingStandards", isDisabled: true },
    { label: "Notify Others", type: "textarea", key: "OrganizationList", isDisabled: true },
    { label: "Completion Date From", type: "date", key: "CompletionDateFrom", isDisabled: true },
    { label: "Completion Date To", type: "date", key: "CompletionDateTo", isDisabled: true },
    { label: "Creation Date", type: "date", key: "CreatedDate", isDisabled: true },
    { label: "Modified Date", type: "date", key: "ModifiedDate", isDisabled: true },
    { label: "Reason for Delete", type: "textarea", key: "ReasonForDelete", isDisabled: true },
];
export var dtnDetailFields = [
    { label: "Committee", type: "text", key: "SubCommitteeName", isDisabled: true, row: 3 },
    { label: "Work Item", type: "hyperlink", key: "WorkItemTrackingNumber", isDisabled: true, row: 3 },
    { label: "Ballot Action", type: "text", key: "DraftActionName", isDisabled: true, row: 3 },
    { label: "", type: "text", key: "emptyField", isDisabled: true, row: 3 },
    { label: "Document Type", type: "text", key: "DocumentType", isDisabled: true, row: 3 },
    { label: "Title", type: "textarea", key: "Title", isDisabled: true, row: 9 },
    { label: "Draft Title Change ", type: "text", key: "DraftTitleChange", isDisabled: true, row: 3 },
    { label: "Replacement Designations", type: "text", key: "ReplacementDesignations", isDisabled: true, row: 3 },
    { label: "Standard Format", type: "text", key: "StandardFormat", isDisabled: true, row: 3 },
    { label: "Companion Designation", type: "text", key: "CompanionDesignation", isDisabled: true, row: 3 },
    { label: "Dependency DTN", type: "hyperlink", key: "DependencyDTN", isDisabled: true, row: 3 },
    { label: "Dependency WI Tracking No.", type: "text", key: "DependencyWITrackingNo", isDisabled: true, row: 3 },
    { label: "Dependency Status", type: "text", key: "DependencyStatus", isDisabled: true, row: 3 },
    { label: "Comment", type: "textarea", key: "DraftComment", isDisabled: true, row: 12 },
    { label: "DTN Creation Date", type: "date", key: "CreatedDate", isDisabled: true, row: 3 },
    { label: "Initial Date", type: "date", key: "InitialDate", isDisabled: true, row: 3 },
    { label: "Modified Date", type: "date", key: "ModifiedDate", isDisabled: true, row: 3 },
];
export var dtnDetailTcnFields = [
    { label: "Account", type: "text", key: "TechnicalContactAccountNumber", isDisabled: true, row: 3 },
    { label: "Full Name", type: "text", key: "TechnicalContactName", isDisabled: true, row: 3 },
    { label: "Phone", type: "text", key: "TechnicalContactPhone", isDisabled: true, row: 3 },
    { label: "Email", type: "text", key: "TechnicalContactEmail", isDisabled: true, row: 3 },
];
export var WorkItemStatusList = [
    { key: 1, text: 'Proposed', value: 1 }
];
export var WorkItemTypeList = [
    { key: 1, text: 'New Standard', value: 1 },
    { key: 2, text: 'Revision', value: 2 },
    { key: 3, text: 'Reapproval', value: 3 },
    { key: 4, text: 'Withdrawal', value: 4 },
    { key: 5, text: 'Reinstatement', value: 5 },
    { key: 6, text: 'Reapproval with Editorial Change', value: 6 },
    { key: 7, text: 'Withdrawal With Replacement', value: 7 }
];
export var WorkItemType = {
    NewStandard: 1,
    Revision: 2,
    Reapproval: 3,
    Withdrawal: 4,
    Reinstatement: 5,
    ReapprovalWithEditorialChange: 6,
    WithdrawalWithReplacement: 7
};
export var YesNoOptions = [
    { key: 1, text: 'Yes', value: true },
    { key: 2, text: 'No', value: false }
];
export var CompletionIntervalList = [
    { Id: 1, Interval: '3 - 6 months' },
    { Id: 2, Interval: '6 - 12 months' },
    { Id: 3, Interval: '12 - 18 months' },
    { Id: 4, Interval: '18 - 24 months' }
];
export var CollabApiFailureActionEnum = {
    Update: 1,
    Delete: 2,
    Restore: 3
};
export var AuditLogTypeEnum = {
    Add: 1,
    Edit: 2,
    Delete: 3,
    Recover: 4,
    InactiveCommittee: 5,
    InactiveTechnicalContact: 6,
    Suppressed: 7,
    PermanentDeleted: 8,
    collabEditApiFailed: 9,
    collabDeleteApiFailed: 10,
    collabRecoverApiFailed: 11
};
