var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Form } from "react-bootstrap";
import DateInput from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { assign } from "lodash";
var ReduxDatePicker = function (_a) {
    var yearChangeHandler = _a.yearChangeHandler, monthChangeHandler = _a.monthChangeHandler, changeHandler = _a.changeHandler, defaultValue = _a.defaultValue, required = _a.required, id = _a.id, isDisabled = _a.isDisabled, handleOnBlur = _a.handleOnBlur, onChangeRawHandler = _a.onChangeRawHandler, isKeyBoardInput = _a.isKeyBoardInput, fieldError = _a.fieldError, _b = _a.meta, touched = _b.touched, error = _b.error, label = _a.label, placeholder = _a.placeholder, _c = _a.input, name = _c.name, value = _c.value, dateFormat = _a.dateFormat, className = _a.className, minDate = _a.minDate, maxDate = _a.maxDate, readOnly = _a.readOnly, icon = _a.icon, restProps = __rest(_a, ["yearChangeHandler", "monthChangeHandler", "changeHandler", "defaultValue", "required", "id", "isDisabled", "handleOnBlur", "onChangeRawHandler", "isKeyBoardInput", "fieldError", "meta", "label", "placeholder", "input", "dateFormat", "className", "minDate", "maxDate", "readOnly", "icon"]);
    var customInputs = assign(restProps, {
        selected: value ? new Date(value) : defaultValue ? new Date(defaultValue) : "",
        dateFormat: dateFormat ? dateFormat : "MM/dd/yyyy",
        placeholderText: placeholder ? placeholder : "MM/DD/YYYY",
        className: fieldError ? "".concat(className ? className : "", " error is-invalid") : className ? className : "",
        minDate: minDate ? new Date(minDate) : null,
        maxDate: maxDate ? new Date(maxDate) : null,
        onChange: function (value) { return changeHandler && changeHandler(value); },
        onBlur: function (_a) {
            var value = _a.target.value;
            return handleOnBlur && handleOnBlur(value);
        },
        onKeyDown: function (event) { return onChangeRawHandler(event); },
        readOnly: readOnly ? readOnly : false,
        disabled: isDisabled,
        peekNextMonth: true,
        showMonthDropdown: true,
        showYearDropdown: true,
        preventOpenOnFocus: true,
        dropdownMode: "select",
        onMonthChange: function (value) { return monthChangeHandler && monthChangeHandler(value); },
        onYearChange: function (value) { return yearChangeHandler && yearChangeHandler(value); },
    });
    return (_jsxs(Form.Group, { children: [label && (_jsxs("label", { className: "".concat(fieldError ? "invalid-feedback block" : "", " block form-label"), htmlFor: id, children: [required && label && _jsx("i", { "aria-hidden": "true", className: "fas fa-asterisk" }), label] })), _jsx("div", { className: "datepickerDV", children: _jsxs("div", { children: [_jsx(DateInput, __assign({}, customInputs, { id: id, disabled: isDisabled })), _jsx("i", { className: "astm-icon far fa-calendar-alt ".concat(icon) })] }) }), fieldError && (_jsx("div", { id: "helpText", className: "invalid-feedback block", children: error }))] }));
};
export default ReduxDatePicker;
