var en = {
    // Access related messages 1001 to 1100
    "1002": { type: "error", text: "You are not able to login due to invalid token." },
    "1003": { type: "error", text: "Token could not be verified from Redis client." },
    "1004": { type: "error", text: "Your session has expired. Please logout, then login again." },
    "1005": { type: "error", text: "Your session has expired. Please logout, then login again." },
    "1010": { type: "error", text: "User name or password missing." },
    "1011": { type: "error", text: "Error occurred during login. Please try again." },
    "1014": { type: "error", text: "Access denied." },
    //User preferences related messages
    "1015": { type: "success", text: "Preference set successfully." },
    "1016": { type: "error", text: "Failed to set User Preferences." },
    "1017": { type: "error", text: "Failed to save ballot header." },
    '1018': { type: 'error', text: "The work Item is deleted in membership but failed to delete the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area delete log has been captured for manual correction by the Help Desk. No further action is required. You can close the application." },
    '1019': { type: 'error', text: "Reason for delete is required" },
    '1020': { type: 'success', text: "Work Item deleted successfully" },
    '3000': { type: 'error', text: 'Error occurred while getting audit logs.' },
    // UI validations related errors
    '4001': { type: 'error', text: 'Incorrect input.' },
    '4002': { type: 'error', text: "'To' date cannot be less than 'From' date." },
    '4003': { type: 'error', text: 'Incorrect date format.' },
    '4004': { type: 'error', text: "'@Date' date cannot be greater than Current date." },
    //Info messages 2000 to 2500
    '2000': { type: 'error', text: 'Work Item not found.' },
    "2001": { type: "info", text: "The work item has a ballot action associated with it." },
    "2002": { type: "info", text: "The work item does not have a ballot action associated with it." },
    "2003": { type: "info", text: "This action will suppress the work item and the associated collaboration area from the web. Work item tracking number and DTN will not be impacted. The Work Item and Collaboration Area (if any) can be restored for 60 days." },
    "2004": { type: "info", text: "The work item will be suppressed and removed from web. Associated work item tracking number, DTN and collaboration area would be deleted. The Work Item and Collaboration area (if any) can be restored for 60 days, after which it will be permanently deleted from the system." },
    '2005': { type: 'info', text: 'No Search Results Found.' },
    '2006': { type: 'info', text: 'Work Item not found.' },
    '2007': { type: 'info', text: 'From date should be less than the To date.' },
    '2008': { type: 'error', text: "'To' date cannot be less than 'From' date." },
    '2009': { type: 'error', text: 'Incorrect date format.' },
    // Info messages 9000 to 9200 Edit Related Messages
    '9000': { type: 'info', text: 'No Task in Upcoming Status' },
    '9001': { type: 'info', text: 'No Task in Open Status' },
    '9002': { type: 'info', text: 'No Task in Done Status' },
    '9003': { type: 'info', text: 'Are you sure you want to break the dependency?' },
    '9004': { type: 'info', text: 'Do you want to break the dependency with this Task(s) and update the record?' },
    '9005': { type: 'info', text: 'Updating Status shall break the dependency with this Task(s). Do you want to update the status?' },
    '9006': { type: 'info', text: 'Do you want to clone the attachments?' },
    '9007': { type: 'info', text: 'Do you want to clone the Task?' },
    '9008': { type: 'info', text: 'Are you sure you want to delete this log?' },
    '9009': { type: 'info', text: 'Are you sure you want to remove this document?' },
    '9010': { type: 'info', text: 'No results found.' },
    '9011': { type: 'info', text: 'No search results found. Try refining your search using the advanced filter.' },
    '9012': { type: 'info', text: 'No logs.' },
    '9013': { type: 'info', text: 'No Search Results Found.' },
    '9014': { type: 'info', text: 'There are no records found! Do you still want to continue downloading an empty file?' },
    '9015': { type: 'info', text: 'No communication logs.' },
    '9016': { type: 'info', text: 'Please select an appropriate option carefully before proceeding with this action.' },
    '9017': { type: 'info', text: 'Please select an option.' },
    '9018': { type: 'info', text: 'Updating Membership Type will remove the association of this Member with @Organization.' },
    '9019': { type: 'info', text: 'Update account status of Organization to Historical and remove association' },
    '9020': { type: 'info', text: 'Let account status of Organization remain Active and remove association' },
    '9021': { type: 'info', text: 'Are you sure you want to perform this action for a Deceased member?' },
    '9022': { type: 'info', text: 'This @membertype is already associated with @Organization.' },
    '9023': { type: 'info', text: 'Update account status of @Organization to Historical and remove association' },
    '9024': { type: 'info', text: 'Let account status of @Organization remain Active' },
    '9025': { type: 'info', text: 'Removing the association with Organization will update the Account Status of this Member to Historical.' },
    '9026': { type: 'info', text: 'The Organization selected has an active association with a @membertype. Please remove the active association by navigating to the @OrganizationLink page and then try again.' },
    '9027': { type: 'info', text: 'Update account status of @Organization to Historical' },
    '9028': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Please update the association with this Organization by navigating to the @RepresentativeLink page.' },
    '9029': { type: 'info', text: 'Membership Type of @SelectedMemberName will be updated from @PreviousMembership to @Representative. Do you want to proceed?' },
    '9030': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Clicking "Yes" button will result in no @membertype assignment for this organization and will update the Account status of linked @membertype to Historical. In order to update the association with this @membertype please navigate to the @RepresentativeLink page. Do you want to proceed?' },
    '9031': { type: 'info', text: 'Membership Type of @SelectedMemberName will be updated from @PreviousMembership to @Representative. Do you want to proceed? Removing the association will update the account status of @CurrentRep to Historical.' },
    '9032': { type: 'info', text: 'Removing the association with @membertype will result in no @membertype assignment for this organization and will update the Account status of @membertype to Historical. Do you want to proceed?' },
    '9033': { type: 'info', text: 'Account status can only be updated to Active when the @membertype account is associated with Organization. Do you want to associate an Organizational account and activate the @membertype account?' },
    '9034': { type: 'info', text: '@Organization was the Organization of this @membertype. Do you want to activate the @membertype account as well as Organizational account?' },
    '9035': { type: 'info', text: 'This Organization is already associated with @Representative.' },
    '9036': { type: 'info', text: 'Update account status of Organization as well as @membertype to Active' },
    '9037': { type: 'info', text: 'Associate another @membertype and remove association with @Representative' },
    '9038': { type: 'info', text: 'Update account status of Organization to active and remove association' },
    '9039': { type: 'info', text: 'No @membertype Account is linked to this Organization.' },
    '9040': { type: 'info', text: 'Update account status of Organization to active without associating @membertype' },
    '9041': { type: 'info', text: 'Update account status of Organization to active and associate another @membertype' },
    '9042': { type: 'info', text: 'The @membertype Member selected has an active association with an Organization. Clicking "Yes" button will update the account status of this Organization to Active with no @membertype associated with it. In order to update the association with this @membertype please navigate to the @RepresentativeLink page. Do you want to proceed?' },
    '9043': { type: 'info', text: 'You are updating Account Status from Historical to Active. Please enter a new Graduation Date.' },
    '9044': { type: 'info', text: "This action will change this member's classification at the Main Committee to PRODUCER. Do you want to proceed?" },
    '9045': { type: 'info', text: 'Are you sure you want to cancel?' },
    '9046': { type: 'info', text: 'Please deselect the Main Committee to deselect a Subcommittee.' },
    '9047': { type: 'info', text: 'Please deselect the Subcommittee to deselect a Main Committee.' },
    '9048': { type: 'info', text: 'Are you sure you want to delete this record? If yes, please provide reason for update.' },
    '9049': { type: 'info', text: 'No Meeting Sequence available.' },
    '9050': { type: 'info', text: 'No Meeting Dates available.' },
    '9051': { type: 'info', text: 'No records to display.' },
    '9052': { type: 'info', text: 'The system will add Member to <Main Committee Designation>. Do you want to proceed?' },
    '9053': { type: 'info', text: 'The system will update the Member Classification to Producer in <Main Committee Designation>. Do you want to proceed?' },
    '9054': { type: 'info', text: 'x - This classification is used in the Balance Rule. To delink this classification, update Balance Rule.' },
    '9055': { type: 'info', text: "y - This classification is assigned to a member. To delink this classification, update the member's classification." },
    '9056': { type: 'info', text: 'z - This classification is Applicable to all @CommitteeType Committees. Delinking requires Bylaws revision and COTCO approval.' },
    '9057': { type: 'info', text: 'Are you sure you want to delete this Committee?' },
    '9058': { type: 'info', text: 'This action will remove the association of Officer Title from Committee. Do you want to continue?' },
    '9059': { type: 'info', text: 'The changes you have made will not be saved. Do you want to leave?' },
    '9060': { type: 'info', text: 'This action is not going to automatically remove the suppression state in Committees created under it.' },
    '9061': { type: 'info', text: 'System will update the Vote Assigned date and Classification Assigned date to Committee Join Date you just entered. Do you want to continue?' },
    '9062': { type: 'info', text: 'System will update the Classification Assigned date to Committee Join Date you just entered. Do you want to continue?' },
    '9063': { type: 'info', text: 'System will update the Vote Assigned date to Committee Join Date you just entered. Do you want to continue?' },
    '9064': { type: 'info', text: 'Select which committees this member has been approved as an affiliate by the respective executive subcommittee per the ASTM Regulations.' },
    '9065': { type: 'info', text: 'The member will be marked as Inactive in those committees in which the member is not approved as an affiliate.' },
    '9066': { type: 'info', text: 'This Member does not have any active committee affiliation.' },
    '9067': { type: 'info', text: 'The system will add Member to <Main Committee Designation> as a Producer. Do you want to proceed?' },
    '9068': { type: 'info', text: 'No active members exists.' },
    '9069': { type: 'info', text: 'This action will reset the settings to default settings which are configured in Rules and Exceptions application. Do you want to continue?' },
    '9070': { type: 'info', text: 'This action will inactivate <Next Level Titles> and rosters under it. Do you want to proceed?' },
    '9071': { type: 'info', text: 'Committee cannot be reactivated as the Committee under which this Committee is created is inactive. Please activate the Committee <Previous Level Committee Designation> and then try again.' },
    '9072': { type: 'info', text: 'Inactivating this Fee Group shall remove the association with [X] Members. Are you sure you want to update the record?' },
    '9073': { type: 'error', text: 'Error occured while getting work item details.' },
    '9074': { type: 'error', text: 'Please enter a Work Item Number' },
    '9075': { type: 'error', text: 'Work Item not found' },
    '9076': { type: 'error', text: 'Error occured while getting Standard Type List.' },
    '9077': { type: 'error', text: 'Error occured while updating work item details.' },
    '9080': { type: 'error', text: 'You must select a Target Ballot Date for this Work Item.' },
    '9081': { type: 'error', text: 'You must enter your Work Item Rationale.' },
    '9082': { type: 'error', text: 'Please assign a Technical Contact' },
    '9083': { type: 'error', text: 'Please provide Emergency Response' },
    '9084': { type: 'error', text: 'You must enter an Emergency Description.' },
    '9085': { type: 'error', text: 'Please select a value' },
    '9086': { type: 'error', text: 'You must enter your Title for the Work Item.' },
    '9087': { type: 'error', text: 'You must enter your Scope for the Work Item.' },
    '9088': { type: 'error', text: 'You must enter your Work Item Keywords.' },
    '9089': { type: 'error', text: 'Please select a value for the Standard Type' },
    '9090': { type: 'error', text: 'Clicking on confirm will update work item details in MCS.' },
    '9091': { type: 'success', text: 'Changes to the work item saved successfully.' },
    '9092': { type: 'error', text: 'Error occured while getting work Item associated ballot status.' },
    '9093': { type: 'info', text: 'The work item does not have a ballot action associated with it' },
    '9094': { type: 'info', text: 'The work item has a ballot action associated with it' },
    '9095': { type: 'info', text: 'Delete Work Item <designation>' },
    '9096': { type: 'info', text: 'This action will delete the work item from MCS and it will be removed from the web. The associated collaboration area would also get deleted. Do you still want to continue?' },
    '9097': { type: 'info', text: 'This action will suppress the work item and the associated collaboration area from the web. It will remain in MCS. Do you still want to continue?' },
    '9098': { type: 'info', text: 'Reason for Delete' },
    '9099': { type: 'error', text: 'Please provide a reason for deleting the work item' },
    '9100': { type: 'success', text: 'Work Item deleted successfully' },
    '9101': { type: 'error', text: 'Error occured while deleting Work Item.' },
    '9102': { type: 'error', text: 'Error occured while restoring Work Item.' },
    '9103': { type: 'success', text: 'Work Item restored successfully' },
    '9104': { type: 'error', text: 'Error occured while getting deleted work item list.' },
    '9105': { type: 'error', text: 'Error occured while getting work item status.' },
    '9106': { type: 'error', text: 'Error occured while getting sub committee list.' },
    '9107': { type: 'error', text: 'Please select sponsoring subcommittee.' },
    '9108': { type: 'error', text: 'Work Item not found.' },
    '9109': { type: 'error', text: 'Error occured while getting technical contact list.' },
    '9110': { type: 'error', text: 'Membership Type is required.' },
    '9111': { type: 'error', text: 'Please select the Main Committee.' },
    '9112': { type: 'error', text: 'Please select the Primary Activity of Member.' },
    '9113': { type: 'error', text: 'Organization Name is required.' },
    '9114': { type: 'error', text: 'Products or Services is required.' },
    '9115': { type: 'error', text: 'Consulting Firm is required.' },
    '9116': { type: 'info', text: 'A consultant retained by an organization, whereby the arrangement includes representing it on an ASTM committee or subcommittee.' },
    '9117': { type: 'info', text: 'A consultant retained by multiple organizations, engaged in the same business activity or different business activities.' },
    '9118': { type: 'error', text: 'University/College/Institution is required.' },
    '9119': { type: 'info', text: 'Does the Organization you represent primarily produce or sell products, materials, systems or services within the scope of the selected committee?' },
    '9120': { type: 'error', text: 'Organization you represent produce/sell is required.' },
    '9121': { type: 'error', text: 'Business activities of the organization you represent is required.' },
    '9122': { type: 'info', text: 'Do any of the organizations you represent primarily produce or sell products, materials, systems or services within the scope of the selected Committee?' },
    '9123': { type: 'error', text: 'Government Agency Name is required.' },
    '9124': { type: 'error', text: 'Nature of your interest is required.' },
    '9125': { type: 'error', text: 'Affiliation is required.' },
    '9126': { type: 'error', text: 'Consumer Advocacy Group Name is required.' },
    '9127': { type: 'error', text: 'Advocacy Group Contact Email is required.' },
    '9128': { type: 'info', text: 'What is the nature of your Consumer interest in the standards developed by the Committees you seek to join?' },
    '9129': { type: 'error', text: 'Organization Account Number is required.' },
    '9130': { type: 'error', text: 'Order Number is required.' },
    '9131': { type: 'error', text: 'Order Date is required.' },
    '9132': { type: 'error', text: 'Error occured while getting demographic details and order details.' },
    '9133': { type: 'error', text: 'Error occured while getting demographic details.' },
    '9134': { type: 'error', text: 'No data found for the entered account number.' },
    '9135': { type: 'error', text: 'First Name is required.' },
    '9136': { type: 'error', text: 'Last Name is required.' },
    '9137': { type: 'error', text: 'Address Line 1 is required.' },
    '9138': { type: 'error', text: 'City is required.' },
    '9139': { type: 'error', text: 'Country is required.' },
    '9140': { type: 'error', text: 'Postal Code is required.' },
    '9141': { type: 'error', text: 'Phone is required.' },
    '9142': { type: 'error', text: 'Invalid Phone.' },
    '9143': { type: 'error', text: 'Email is required.' },
    '9144': { type: 'error', text: 'Organization Name is required.' },
    '9145': { type: 'error', text: 'State/Province is required.' },
    '9146': { type: 'error', text: 'Free Volume is required.' },
    '9147': { type: 'error', text: 'Volume Format is required.' },
    '9148': { type: 'error', text: 'Error occured while getting Free Volume.' },
    '9149': { type: 'error', text: 'Please enter a member account number.' },
    '9150': { type: 'error', text: 'Please enter a valid member account number.' },
    '9151': { type: 'error', text: 'Error occured while getting member account number details.' },
    '9152': { type: 'info', text: 'Are you sure you want to skip this step?' },
    '9153': { type: 'error', text: 'Name of your College/University is required.' },
    '9154': { type: 'error', text: 'Anticipated Graduation Date is required.' },
    '9155': { type: 'error', text: 'Month is required.' },
    '9156': { type: 'error', text: 'Error occured while getting ballot item list.' },
    '9157': { type: 'error', text: 'Organization Account Number can not be the same as Representative Account Number.' },
    '9158': { type: 'error', text: 'Error occured while getting View Vote History.' },
    '9159': { type: 'error', text: 'Member Fee status Should not be Paid!.' },
    '9160': { type: 'error', text: 'Account Number/Organization Account Number already exists.' },
    '9161': { type: 'error', text: 'Please add this member to the committees in order to update the Membership type to Participating.' },
    '9162': { type: 'error', text: 'This committee has active standards associated with it. Members will not be able to submit work items or ballot items for existing standards or new standards for this committee(/sub). Do you want to continue?' },
    '9163': { type: 'error', text: 'The same member will be assigned as the <Administrative Assistant OR Staff Manager> in the <Main Committee Designation> and all the subcommittees/sections under it. Are you sure you want to continue?' },
    '9164': { type: 'error', text: 'Address Line 2 is required.' },
    '9165': { type: 'error', text: 'Error occured while creating new member.' },
    '9166': { type: 'error', text: 'The selected members will be assigned as the Staff Manager, Administrative Assistant in the <Main Committee Designation> and all the subcommittees/sections under it. Are you sure you want to continue?' },
    '9167': { type: 'success', text: 'Membership account created successfully' },
    '9168': { type: 'error', text: 'Error occured while getting order details.' },
    '9169': { type: 'success', text: 'Membership account renewed successfully.' },
    '9170': { type: 'success', text: 'Membership account reinstated successfully.' },
    '9171': { type: 'success', text: 'Membership account upgraded successfully.' },
    '9172': { type: 'info', text: 'The Organization Name & Address combination is already in the system for @Organization. The changes cannot be saved. Please verify the information is correct to avoid duplicate records.' },
    '9173': { type: 'info', text: 'This organization already exists. Do you want to associate this member to the existing ORG?' },
    '9174': { type: 'error', text: 'The work Item is restored in membership but failed to restore the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area restore log has been captured for manual correction by the Help Desk. No further action is required. You can close the application. ' },
    '9175': { type: 'error', text: 'The work Item is updated in membership but failed to update the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area update log has been captured for manual correction by the Help Desk. No further action is required. You can close the application.' },
    '9176': { type: 'error', text: 'The work Item is deleted in membership but failed to delete the associated collaboration area due to non-responsive API action. The Work Item and Collaboration Area delete log has been captured for manual correction by the Help Desk. No further action is required. You can close the application.' },
    '9177': { type: 'error', text: 'We cannot verify the address you have provided. Do you want to proceed with the address you have entered?' },
    '9178': { type: 'error', text: 'We have slightly modified the address entered. If correct, please use the suggested address to ensure accurate delivery.' },
    '9179': { type: 'error', text: 'No search results found. Try refining your search using the advanced filter.' },
    '9180': { type: 'error', text: 'Please enter the active college/university account number.' },
    '9181': { type: 'error', text: 'Please enter the active organization account number.' },
    '9182': { type: 'error', text: 'Please enter the active college/university account details.' },
    '9183': { type: 'error', text: 'Please enter the active organization account details.' },
    '9184': { type: 'error', text: 'There are currently active student members associated with the committee. Please manually update their status to inactive if necessary.' },
    '9185': { type: 'info', text: 'Student Member participation in committee' },
    '9186': { type: 'info', text: 'Student member program is disabled for the committee.' }
};
export default en;
